import axiosApiClient from 'src/api/axios-client';
import * as api from 'src/api/generated';
import { API_BASE_URL } from 'src/constants';

const baseConfig = (): api.Configuration => ({
    isJsonMime: () => false,
    accessToken: localStorage?.accessToken,
    baseOptions: {
        headers: {
            'accept-Language': localStorage.LocalizationStore
                ? JSON.parse(localStorage.LocalizationStore)?.current?.code
                : 'en'
        }
    }
})

// Auth API
export const authApi = (): api.AuthApi => new api.AuthApi(baseConfig(), API_BASE_URL, axiosApiClient);
// Users API
export const usersApi = (): api.UserApi => new api.UserApi(baseConfig(), API_BASE_URL, axiosApiClient);
// Media API
export const mediaApi = (): api.MediaApi => new api.MediaApi(baseConfig(), API_BASE_URL, axiosApiClient);
// Media paths API
export const mediaPathsApi = (): api.MediaPathApi => new api.MediaPathApi(baseConfig(), API_BASE_URL, axiosApiClient);
// Languages API
export const languagesApi = (): api.LanguageApi => new api.LanguageApi(baseConfig(), API_BASE_URL, axiosApiClient);
// Mail API
export const mailApi = (): api.MailApi => new api.MailApi(baseConfig(), API_BASE_URL, axiosApiClient);
// Artworks API
export const artworksApi = (): api.ArtworkApi => new api.ArtworkApi(baseConfig(), API_BASE_URL, axiosApiClient);
// Techniques API
export const techniquesApi = (): api.TechniqueApi => new api.TechniqueApi(baseConfig(), API_BASE_URL, axiosApiClient);
// Topics API
export const topicsApi = (): api.TopicApi => new api.TopicApi(baseConfig(), API_BASE_URL, axiosApiClient);
// Exhibitions API
export const exhibitionsApi = (): api.ExhibitionApi => new api.ExhibitionApi(baseConfig(), API_BASE_URL, axiosApiClient);
// Places API
export const placesApi = (): api.PlaceApi => new api.PlaceApi(baseConfig(), API_BASE_URL, axiosApiClient);
// Owners API
export const ownersApi = (): api.OwnerApi => new api.OwnerApi(baseConfig(), API_BASE_URL, axiosApiClient);
// Documents API
export const documentsApi = (): api.DocumentApi => new api.DocumentApi(baseConfig(), API_BASE_URL, axiosApiClient);
// Photos API
export const photosApi = (): api.PhotoApi => new api.PhotoApi(baseConfig(), API_BASE_URL, axiosApiClient);