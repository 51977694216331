import { Suspense, lazy, FC } from 'react'
import type { RouteObject } from 'react-router'
import AuthGuard from 'src/components/AuthGuard'
import GuestGuard from 'src/components/GuestGuard'
import ActivationGuard from 'src/components/ActivationGuard'
import LoadingScreen from 'src/components/LoadingScreen'
import MainLayout from 'src/components/MainLayout'
import DashboardLayout from 'src/components/dashboard/DashboardLayout'
import Utilities from 'src/lib/utilities'
import { UserRoleEnum } from 'src/api/generated'
import { default as AdminDashboard } from 'src/pages/dashboard/@admin/Dashboard'
import { default as GuestDashboard } from 'src/pages/dashboard/@guest/Dashboard'
import RoleGuard from 'src/components/RoleGuard'
import WindowClose from 'src/components/WindowClose'

const Loadable = (Component: FC) => (props: any) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
)

// --- Authentication pages

const Login = Loadable(lazy(() => import('src/pages/authentication/Login')))
// const Register = Loadable(lazy(() => import('src/pages/authentication/Register')))
const PasswordRecovery = Loadable(lazy(() => import('src/pages/authentication/PasswordRecovery')))
const PasswordReset = Loadable(lazy(() => import('src/pages/authentication/PasswordReset')))
const ActivateUser = Loadable(lazy(() => import('src/pages/authentication/UserActivation')))

// --- Dashboard pages

const FileManager = Loadable(lazy(() => import('src/pages/dashboard/FileManager')))
const Account = Loadable(lazy(() => import('src/pages/dashboard/Account')))
const Dashboard = Loadable(() => <>{Utilities.getUserRole() === UserRoleEnum.Admin ? <AdminDashboard /> : <GuestDashboard />}</>)
const LanguagesList = Loadable(lazy(() => import('src/pages/dashboard/languages/LanguagesList')));
const LanguageSingle = Loadable(lazy(() => import('src/pages/dashboard/languages/LanguageSingle')));
const UsersList = Loadable(lazy(() => import('src/pages/dashboard/users/UsersList')));
const UserSingle = Loadable(lazy(() => import('src/pages/dashboard/users/UserSingle')));
const ArtworksList = Loadable(lazy(() => import('src/pages/dashboard/artworks/ArtworksList')));
const ArtworkSingle = Loadable(lazy(() => import('src/pages/dashboard/artworks/ArtworkSingle')));
const ExhibitionsList = Loadable(lazy(() => import('src/pages/dashboard/exhibitions/ExhibitionsList')));
const ExhibitionSingle = Loadable(lazy(() => import('src/pages/dashboard/exhibitions/ExhibitionSingle')));
const TopicsList = Loadable(lazy(() => import('src/pages/dashboard/topics/TopicsList')));
const TopicSingle = Loadable(lazy(() => import('src/pages/dashboard/topics/TopicSingle')));
const TechniquesList = Loadable(lazy(() => import('src/pages/dashboard/techniques/TechniquesList')));
const TechniqueSingle = Loadable(lazy(() => import('src/pages/dashboard/techniques/TechniqueSingle')));
const PlacesList = Loadable(lazy(() => import('src/pages/dashboard/places/PlacesList')));
const PlaceSingle = Loadable(lazy(() => import('src/pages/dashboard/places/PlaceSingle')));
const OwnersList = Loadable(lazy(() => import('src/pages/dashboard/owners/OwnersList')));
const OwnerSingle = Loadable(lazy(() => import('src/pages/dashboard/owners/OwnerSingle')));
const DocumentsList = Loadable(lazy(() => import('src/pages/dashboard/documents/DocumentsList')));
const DocumentSingle = Loadable(lazy(() => import('src/pages/dashboard/documents/DocumentSingle')));
const PhotosList = Loadable(lazy(() => import('src/pages/dashboard/photos/PhotosList')));
const PhotoSingle = Loadable(lazy(() => import('src/pages/dashboard/photos/PhotoSingle')));

// --- Error pages

const AuthorizationRequired = Loadable(lazy(() => import('src/pages/AuthorizationRequired')))
const NotFound = Loadable(lazy(() => import('src/pages/NotFound')))
const ServerError = Loadable(lazy(() => import('src/pages/ServerError')))
const ActivationRequired = Loadable(lazy(() => import('src/pages/ActivationRequired')))

// --- Other pages

const Home = Loadable(lazy(() => import('src/pages/Home')))

const routes: RouteObject[] = [
  {
    path: 'authentication',
    element: <GuestGuard />,
    children: [
      {
        path: 'login',
        element: <Login />
      },
      // {
      //   path: 'register',
      //   element: <Register />
      // },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />
      },
      {
        path: 'password-reset/:token',
        element: <PasswordReset />
      },
    ]
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <ActivationGuard>
          <DashboardLayout />
        </ActivationGuard>
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: 'filemanager',
        element: <RoleGuard roles={[UserRoleEnum.Admin]}><FileManager /></RoleGuard>
      },
      {
        path: 'account',
        element: <Account />
      },
      {
        path: 'languages',
        element: <RoleGuard roles={[UserRoleEnum.Admin]} />,
        children: [
          {
            index: true,
            element: <LanguagesList />
          },
          {
            path: ':id',
            element: <LanguageSingle />
          }
        ]
      },
      {
        path: 'users',
        element: <RoleGuard roles={[UserRoleEnum.Admin]} />,
        children: [
          {
            index: true,
            element: <UsersList />
          },
          {
            path: ':id',
            element: <UserSingle />
          }
        ]
      },
      {
        path: 'artworks',
        element: <RoleGuard roles={[UserRoleEnum.Admin]} />,
        children: [
          {
            index: true,
            element: <ArtworksList />
          },
          {
            path: ':id',
            element: <ArtworkSingle />
          }
        ]
      },
      {
        path: 'exhibitions',
        element: <RoleGuard roles={[UserRoleEnum.Admin]} />,
        children: [
          {
            index: true,
            element: <ExhibitionsList />
          },
          {
            path: ':id',
            element: <ExhibitionSingle />
          }
        ]
      },
      {
        path: 'topics',
        element: <RoleGuard roles={[UserRoleEnum.Admin]} />,
        children: [
          {
            index: true,
            element: <TopicsList />
          },
          {
            path: ':id',
            element: <TopicSingle />
          }
        ]
      },
      {
        path: 'techniques',
        element: <RoleGuard roles={[UserRoleEnum.Admin]} />,
        children: [
          {
            index: true,
            element: <TechniquesList />
          },
          {
            path: ':id',
            element: <TechniqueSingle />
          }
        ]
      },
      {
        path: 'places',
        element: <RoleGuard roles={[UserRoleEnum.Admin]} />,
        children: [
          {
            index: true,
            element: <PlacesList />
          },
          {
            path: ':id',
            element: <PlaceSingle />
          }
        ]
      },
      {
        path: 'owners',
        element: <RoleGuard roles={[UserRoleEnum.Admin]} />,
        children: [
          {
            index: true,
            element: <OwnersList />
          },
          {
            path: ':id',
            element: <OwnerSingle />
          }
        ]
      },
      {
        path: 'documents',
        element: <RoleGuard roles={[UserRoleEnum.Admin]} />,
        children: [
          {
            index: true,
            element: <DocumentsList />
          },
          {
            path: ':id',
            element: <DocumentSingle />
          }
        ]
      },
      {
        path: 'photos',
        element: <RoleGuard roles={[UserRoleEnum.Admin]} />,
        children: [
          {
            index: true,
            element: <PhotosList />
          },
          {
            path: ':id',
            element: <PhotoSingle />
          }
        ]
      }
    ]
  },
  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <Home />
      },
      {
        path: 'close',
        element: <WindowClose />
      },
      {
        path: 'activate-user/:token',
        element: <ActivateUser />
      },
      {
        path: '400',
        element: <ActivationRequired />
      },
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
]

export default routes