import { RESET } from 'jotai/utils'
import { SetStateAction } from 'jotai'
import { LoginCredentialsDto, User } from 'src/api/generated';
import { jwtDecode as jwt_decode } from "jwt-decode";
import { authApi, usersApi } from 'src/api/api';
import atomWithLocalStorage from 'src/lib/atomWithLocalStorage';
import { StoreI } from 'src/store';

export interface AuthStoreI {
    isInitialized: boolean;
    isAuthenticated: boolean;
    user: User;
}

const Atom = atomWithLocalStorage<AuthStoreI>(
    "AuthStore",
    {
        isInitialized: false,
        isAuthenticated: false,
        user: null
    }
)

const Reducer = (current: AuthStoreI, setter: (update?: typeof RESET | SetStateAction<AuthStoreI>) => void) => {
    return {
        set: async (payload: Partial<AuthStoreI>): Promise<void> => {
            await setter({ ...current, ...payload })
        },

        reset: async (): Promise<void> => {
            await setter(RESET)
        },

        initialize: async (): Promise<void> => {
            try {
                const accessToken = window.localStorage.getItem('accessToken');
                if (accessToken) {
                    const { userId } = jwt_decode(accessToken) as any;
                    const user = (await usersApi().getOneUser({ id: userId }))?.data;
                    await setter({ ...current, isInitialized: true, isAuthenticated: true, user })
                } else await setter({ ...current, isInitialized: true, isAuthenticated: false, user: null })
            } catch (error) {
                console.error(error);
                await setter({ ...current, isInitialized: true, isAuthenticated: false, user: null })
            }
        },

        jwt: async (): Promise<string> => {
            return (await authApi().jwt())?.data?.jwt;
        },

        login: async (payload: { credentials: LoginCredentialsDto }): Promise<void> => {
            const accessToken = (await authApi().login({ loginCredentialsDto: payload.credentials }))?.data?.token;
            const { userId } = jwt_decode(accessToken) as any;
            const user = (await usersApi().getOneUser({ id: userId }, { headers: { Authorization: `Bearer ${accessToken}` } }))?.data;
            await Reducer(current, setter).loggedIn({ user, accessToken })
        },

        loggedIn: async (payload: { user: User, accessToken: string }): Promise<void> => {
            localStorage.setItem('accessToken', payload.accessToken);
            const user = (await usersApi().getOneUser({ id: payload.user?.id }))?.data;
            await setter({ ...current, isAuthenticated: true, user })
        },

        logout: async (): Promise<void> => {
            localStorage.removeItem('accessToken');
            await setter({ ...current, isAuthenticated: false, user: null })
        },

        register: async (payload: { user: User }): Promise<void> => {
            const accessToken = await Reducer(current, setter).jwt()
            localStorage.setItem('accessToken', accessToken);
            const user = (await usersApi().createOneUser({ user: payload.user }, { headers: { Authorization: `Bearer ${accessToken}` } }))?.data;
        }
    }
}

const AuthStore: StoreI<AuthStoreI, typeof Reducer> = { atom: Atom, reducer: Reducer }

export default AuthStore